import React from "react";
import AboutUs from '../../components/AboutUs/AboutUs';
import Header from '../../components/Header/Header';
import Testimonials from '../../components/Testimonials/Testimonials';
import MainSection from '../../components/MainSection/MainSection';

function Home() {
  return (
    <>
      <Header />
      <MainSection />
      <AboutUs />
      <Testimonials />
    </>
  );
}

export default Home;
