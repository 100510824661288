import React from "react";
import { MainSectionContainer } from "./mainSectionStyles";

const MainSection = props => {
  return (
    <MainSectionContainer id="home">

      <div className="main-content">
        <h1>
         <p className='main-h1'>Fale com um consultor agora</p>
          <a class="wpp-button" href="https://api.whatsapp.com/send?phone=5585997171518" target="_blank" >
            <i class="wpp-icon"></i>
          </a>  
        </h1>

        {/* <a href="#contato" className="main-btn">
          <ButtonPrimary>
            Quero um orçamento
          </ButtonPrimary>
        </a> */}
      </div>

      <ul>
        <li className="carros">
          <h2>Seguro automóvel</h2>
          <p>Total segurança para seu automóvel e moto.</p>
        </li>
        {/* <li className="pratimonial">
          <h2>Seguro pratimonial</h2>
          <p>Mantenha os seus bens em segurança.</p>
        </li> */}
        <li className="pratimonial">
          <h2>Seguro incêndio</h2>
          <p>Mantenha seu imóvel/bens em segurança.</p>
        </li>
        {/* <li className="previdencia">
          <h2>Previdência privada</h2>
          <p>A melhor opção para manter seu estilo de vida.</p>
        </li> */}
        <li className="viagem">
          <h2>Seguro viagem</h2>
          <p>Uma viagem mais tranquila com cobertura para COVID-19.</p>
        </li>
        <li className="vida">
          <h2>Seguro de vida</h2>
          <p>Garantia de uma vida tranquila.</p>
        </li>
      </ul>
    </MainSectionContainer>
  )
}

export default MainSection;
