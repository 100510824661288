import React, { useEffect, useState } from "react";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { TestimonialsContainer } from "./testimonialsStyles";
import { getTestimonials } from "../../providers/mainProvider";


const Testimonials = props => {

  const [list, setList] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await getTestimonials();
      if(result.error) {
        return;
      }
      setList(result);
    }
    getData();
  }, []);

  return (
    <TestimonialsContainer id="depoimentos">

      <CarouselProvider
        naturalSlideWidth={125}
        naturalSlideHeight={200}
        totalSlides={list.length}
        isPlaying={true}
      >
        <h2>Depoimentos</h2>
        <Slider>
          {list.map((item,index) => (
            <Slide index={index} key={`testemony-${item.id}`}>
              <div className="slide-testimonials">
                {/* <img src="https://weyneseguros.com.br/wp-content/uploads/2015/11/f8967a13-ac74-46a8-93d4-f256c9c0eacf.jpg" /> */}
                <p>"{item.description}"</p>
                <h3>{item.name}</h3>
              </div>
            </Slide>
          ))}
        </Slider>
        <DotGroup />
      </CarouselProvider>
      
      
    </TestimonialsContainer>
  )
}

export default Testimonials;
