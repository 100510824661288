import React from "react";
import { FooterContainer } from "./footerStyles";

import Logo from "../../assets/logo-symbol.png";
import { ImageButton } from "../../styles/buttons";

const Footer = props => {
  return (
    <FooterContainer>

      <section className="logo-container">
        <ImageButton width="162px" height="60px" icon={Logo}>
          <a href="/#home">
            Weyne Seguros
          </a>
        </ImageButton>
      </section>

      <section>
        <h2>Nossa Visão</h2>
        <p>Oferecer com agilidade as melhores soluções em seguros, adequando perfeitamente às necessidades de cada cliente, com o objetivo de proteger seu patrimônio e garantir a tranquilidade da sua família. </p>
      </section>

      <section className="contact-info">
        <h2>Informações de contato</h2>
        <ul>
          <li className="phone">Fone: (85) 99717-1518 / 98768-2853</li>
          <li className="email">comercial@weyneseguros.com.br</li>
          <li className="address">Av. Dom luis, 176 - Fortaleza/CE</li>
        </ul>
        <h2>Horário de funcionamento</h2>
        <p>Segunda a Sexta: 09:00 - 18:00hrs</p>
      </section>

      
      
    </FooterContainer>
  )
}

export default Footer;
