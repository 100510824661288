import styled from "styled-components";

export const TestimonyFormHeader = styled.header`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 0px 3px 0px rgba(46,65,127,0.2);
  background-color: var(--color-bg);
`

export const TestimonyFormContainer = styled.main`
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding: 1rem;

  h1 {
    color: var(--color-primary);
    text-align: center;
    margin-bottom: 0.25em;
  }

  p {
    text-align: center;
  }

  form {
    width: 50%;
    min-width: 280px;
    margin: 2rem auto;
    & > label {
      margin-bottom: 1rem;
    }
    button {
      display: block;
      margin: auto;
    }
  }

  .alert-msg {
    margin-bottom: 1rem;
    line-height: normal;
    text-align: left;
  }

  @media (min-width: 820px) {
    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      .col-2 {
        grid-column-end: span 2;
      }
    }
  }

`
