import React, { useState } from "react";
import { Button, ImageButton } from "../../styles/buttons";
import { LandingHeader, NavMenu } from "./headerStyles";

import Logo from "../../assets/logo-symbol.png";

const Header = props => {
  const [showNav, setShowNav] = useState(false);
  return (
    <LandingHeader>
      
      <ImageButton width="146px" height="41px" icon={Logo}>
          Contabilizar
      </ImageButton>


      <NavMenu show={showNav}>
        <a href="/#home" onClick={() => setShowNav(!showNav)}>
          <Button>Início</Button>
        </a>
        <a href="/#quem-somos" onClick={() => setShowNav(!showNav)}>
          <Button>Quem somos</Button>
        </a>
        <a href="/#depoimentos" onClick={() => setShowNav(!showNav)}>
          <Button>Depoimentos</Button>
        </a>
      </NavMenu>
      
      <Button id="menu-btn" onClick={() => setShowNav(!showNav)}>
        {showNav ? "Fechar" : "Menu"}
      </Button>
      
    </LandingHeader>
  )
}

export default Header;
