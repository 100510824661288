const baseUrl = "https://admin.weyneseguros.com.br/api/";
// const baseUrl = "http://weyneseguros-api.devaribox.co/api/";

export const getTestimonials = async () => {
  try {
    const result = await fetch(`${baseUrl}v1/testimony/?accept=true`);

    if (!result.ok) {
      throw { error: true };
    }

    const data = await result.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

export const sendTestimonial = async (payload) => {
  try {
    const result = await fetch(
      `${baseUrl}v1/testimony/`,
      {
        method: "post",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        }
      }
    );

    if (!result.ok) {
      throw { error: true };
    }

    const data = await result.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};
