import styled from "styled-components";
import testimonialsBG from "../../assets/testimonials-bg.png";

export const TestimonialsContainer = styled.section`

  &#depoimentos {
    
  }

  .carousel {
    background: var(--color-primary-darker2) url(${testimonialsBG});
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--color-bg);
    padding: 3rem 1rem;
  }

  h2 {
    text-align: center;
    font-weight: 600;
  }

  .carousel__slider {
    min-height: 400px;
  }

  button.carousel__dot {
    width: 15px;
    height: 15px;
    background-color: rgba(255,255,255,0.4);
    border-radius: 50%;
    margin: 0 0.25rem;

    &.carousel__dot--selected {
      border: 3px solid white;
      background-color: transparent;
    }
  }

  .carousel__dot-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-testimonials {
    display: flex;
    padding: 0 1rem 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* height: 100%; */
    max-width: 900px;
    margin: auto;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      margin: 1.5rem 0;
    }

    h3 {
      font-size: 0.875rem;
      margin: 0;
      opacity: 0.7;
      font-weight: 600;
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 1rem 0;
    }
    
  }

  @media (min-width: 400px) {
    .carousel__slider {
      min-height: unset;
      max-height: 440px;
    }
  }

  @media (min-width: 660px) {
    .carousel__slider {
      min-height: unset;
      max-height: 280px;
    }
  }
  
`