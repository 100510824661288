import styled from "styled-components";

export const FooterContainer = styled.footer`
  
  background-color: var(--color-secondary);
  color: var(--color-bg);
  padding: 2rem;
  font-weight: 500;

  h2 {
    font-size: 1rem;
    text-transform: uppercase;
  }

  p, li {
    color: var(--color-font-light);
  }

  & > section {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &.logo-container {
      align-items: center;
    }

    &.contact-info {
      /* padding-left: 1rem; */
    }

    ul > li {
      margin: 0.5rem 0;
    }

  }

  @media (min-width: 840px) {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    & > section {
      flex: 1;
      margin: 0 1rem;
      &:not(:last-child) {
        margin-bottom: 0;
      }
      &.logo-container {
        align-self: center;
      }
    }
  }

  @media (min-width: 1024px) {

    & > section {
      margin: 0 2rem;
    }
  }

  @media (min-width: 1200px) {
    justify-content: center;
    & > section {
      max-width: 400px;
    }
  }
`
