import styled from "styled-components";
import patrimonial from "../../assets/icon-home.png";
import carros from "../../assets/icon-car.png";
import previdencia from "../../assets/icon-luxury.png";
import viagem from "../../assets/icon-travel.png";
import vida from "../../assets/icon-heart.png";
import wpp from "../../assets/whatsapp-logo.png";

export const MainSectionContainer = styled.section`

  min-height: 100vh;
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-rows: 1fr auto;

  .main-content {
    align-self: center;
    padding: 0 1rem;
  }

  h1 {
    font-size: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    padding: 0 1rem;
    margin: 3.5rem auto;
    max-width: 1200px;

    /* &::after {
      content: "";
      display: block;
      width: 60px;
      height: 2px;
      margin: 1.25rem auto;
      background-color: var(--color-primary);
    } */
  }

  .main-h1 {
    color: var(--color-primary-darker3);
  }

  .wpp-button {
    font-size: 1.2rem;
    color: white;
    background-color: rgb(0, 184, 76);
    padding: 1rem;
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto;
    margin-top: 0.75rem;
    border-radius: 50%;
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
  }
  
  .wpp-icon {
    background-image: url(${wpp});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 4.6rem;
    height: 4.6rem;
    display: block;
  }

  .main-btn {
    margin: auto;
    display: block;
    width: fit-content;
    button {
      margin: auto;
      display: block;
    }
  }


  ul {
    margin-top: 3rem;
    & > li {
      padding: 2rem 1.5rem;
      background-color: var(--color-primary);
      color: var(--color-bg);
      h2 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1rem;
        margin-top: 0;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          margin-right: 0.5rem;
          background-image: url(${carros});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          width: 2rem;
          height: 2rem;
        }
      }
      p {
        font-size: 0.875rem;
      }
      &.pratimonial {
        background-color: var(--color-primary-darker1);
        h2::before {
          background-image: url(${patrimonial});
        }
      }
      &.previdencia {
        background-color: var(--color-primary-darker2);
        h2::before {
          background-image: url(${previdencia});
        }
      }
      &.viagem {
        background-color: var(--color-primary-darker2);
        h2::before {
          background-image: url(${viagem});
        }
      }
      &.vida {
        background-color: var(--color-primary-darker3);
        h2::before {
          background-image: url(${vida});
        }
      }
    }
  }
  
  @media (min-width: 642px) {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: 1274px) {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`