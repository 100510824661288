import styled from "styled-components";
import checkmark from "../../assets/checkmark.svg";

export const AboutUsContainer = styled.section`

  padding-right: 1rem;
  padding-left: 1rem;
  /* padding-bottom: calc(var(--header-height) / 2); */
  
  h2 {
    font-size: 1.75rem;
  }

  p, li {
    color: var(--color-font-light);
  }

  ul {
    margin-top: 2rem;
    li {
      margin: 0.75rem 0;
      i {
        margin-right: 1rem;
        color: var(--color-primary);
        background-image: url(${checkmark});
        background-size: contain;
        
        display: inline-block;
        width: 0.85rem;
        height: 0.85rem;

      }
    }
  }

  & > img {
    max-width: 100%;
  }
  
  @media (min-width: 680px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    max-width: 1200px;
    margin: auto;
  }

  /* @media (min-width: 1274px) {
    
  } */
`