import styled from "styled-components";


export const LandingHeader = styled.header`
  /* --header-height: 100px; */
  z-index: 99;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: var(--header-height);
  box-shadow: 0px 0px 3px 0px rgba(46,65,127,0.2);
  background-color: var(--color-bg);
  /* #menu-btn {
    width: 110px;
  } */
  @media (min-width: 720px) {
    padding: 0 3rem;
    grid-template-columns: 100px auto;
    #menu-btn {
      display: none;
    }
  }
`

export const NavMenu = styled.nav`
  display: inline-block;
  a button {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  @media (max-width: 720px) {
    display: ${({ show = false }) => show ? "block" : "none"};
    background-color: var(--color-secondary);
    position: fixed;
    width: 100vw;
    top: var(--header-height);
    height: calc(100vh - var(--header-height));
    left: 0;
    flex-direction: column;
    padding: 1rem;
    #login-btn {
      border: none;
      margin: 0;
    }
    & > * {
      display: block;
    }
    & > *, & > * button {
      color: var(--color-bg);
    }
  }
`;
