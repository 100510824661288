import React from "react";
import { AboutUsContainer } from "./aboutUsStyles";
import contractImage from "../../assets/contrato-menor.jpg";

const AboutUs = props => {
  return (
    <AboutUsContainer id="quem-somos">
      <div className="content">
        <h2>Quem somos nós?</h2>
        <p>Somos uma empresa apaixonada por encontrar soluções seguras para nossos clientes. Contamos com uma grande equipe para lhe dar todo o suporte necessário.</p>

        <ul>
          <li>
            <i className="check"></i>
            Soluções personalizadas: tratamos cada cliente de forma individualizada de acordo com sua necessidade.
          </li>
          <li>
            <i className="check"></i>
            Transparência: você entende exatamente o tipo de seguro que você esta adquirindo.
          </li>
          <li>
            <i className="check"></i>
            Experiência: Há mais de 10 anos trabalhamos com seguros e protegendo suas conquistas.
          </li>
        </ul>
      </div>

      <img src={contractImage} alt="Quem é Weyne Seguros?" />
      
      
    </AboutUsContainer>
  )
}

export default AboutUs;
