import React, { useState } from "react";
import { TestimonyFormContainer, TestimonyFormHeader } from "./styles";
import Logo from "../../assets/logo-symbol.png";
import { Alert, AlertSucess, Input, Label, Textarea } from "../../styles/forms";
import { ButtonPrimary } from "../../styles/buttons";
import { sendTestimonial } from "../../providers/mainProvider";

const initialPayload = {
  "name": "",
  "city": "",
  "state": "",
  "description": ""
}

function TestimonyForm() {

  const [payload, setPayload] = useState(initialPayload);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onChange = (e) => {
    setPayload({...payload, [e.target.name]: e.target.value});
  }

  const isValid = () => {
    if(!payload.name) {
      setError("Preencha seu nome");
      return false
    }

    if(!payload.description) {
      setError("É necessário escrever o texto de seu depoimento para enviá-lo");
      return false
    }

    return true;
  }

  const send = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    
    if(!isValid()) {
      return;
    }
    
    setLoading(true);

    const result = await sendTestimonial(payload);
    if(result.error) {
      setError("Ocorreu um erro e não foi possível enviar seu depoimento");
      setLoading(false);
      return;
    }

    setSuccess("Agradecemos seu depoimento! Conte sempre com a Weyne Seguros!");
    setPayload(initialPayload);
    setLoading(false);

  }

  return (
    <>
      <TestimonyFormHeader>
        <img src={Logo} alt="Weyne Seguros"/>
      </TestimonyFormHeader>
      <TestimonyFormContainer>
        <h1>Depoimento</h1>
        <p>Esperamos que sua experiência tenha sido excelente, gostaríamos de ouvir sua opinião</p>
        <form>
          <Label className="col-2">
            Seu Nome
            <Input name="name" value={payload.name} onChange={onChange} />
          </Label>
          {/* <Label>
            Cidade
            <Input name="city" value={payload.city} onChange={onChange} />
          </Label>
          <Label>
            Estado
            <Input name="state" value={payload.state} onChange={onChange} />
          </Label> */}
          <Label className="col-2">
            Depoimento
            <Textarea rows="6" name="description" value={payload.description} onChange={onChange} ></Textarea>
          </Label>

          <Alert className="alert-msg col-2" show={error}>{error}</Alert>
          <AlertSucess className="alert-msg col-2" show={success}>{success}</AlertSucess>

          <ButtonPrimary className="col-2" onClick={send}>
            {loading ? "Carregando" : "Enviar"}
          </ButtonPrimary>
        </form>
      </TestimonyFormContainer>
      
    </>
  );
}

export default TestimonyForm;
