import Footer from './components/Footer/Footer';
import Home from './screens/Home/Home';
import TestimonyForm from './screens/TestimonyForm/TestimonyForm';
import './styles/app.css';

import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>

          <Switch>
            <Route path="/formulario-depoimento">
              <TestimonyForm />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>


        <Footer />

      </Router>
    </div>
  );
}

export default App;
